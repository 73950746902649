import ReactDOM from "react-dom/client";
import App from "./App";
import env from "./config/env";
import "./i18n";
import "./index.css";
import { NotificationService, SentryService, ServiceWorker } from "./services";

const root = ReactDOM.createRoot(document.getElementById("root"));

SentryService.init();

root.render(<App />);

if (env.ENV === "local") {
  ServiceWorker.unregister();
} else {
  ServiceWorker.register({
    onUpdate: (version: string) => {
      NotificationService.showNewVersion(true);
      NotificationService.updateAppVersion(version);
    },
  });
}
