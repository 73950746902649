import { UserApi } from "@/api";
import Icon from "@/components/base/Icon";
import TitleWrapper from "@/components/base/page-container/title-wrapper";
import FormReseller from "@/components/features/reseller/forms";
import useNotification from "@/hooks/useNotification";
import ResellerStore from "@/store/reseller";
import UiStore from "@/store/ui";
import UserSessionStore from "@/store/user-session";
import { Button, CircularProgress, Paper } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ResellersEdit = () => {
  const { updateState } = UiStore();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { resellerInfos } = ResellerStore();
  const [isSendingAccess, setIsSendingAccess] = useState(false);
  const { preferredLanguage } = UiStore();
  const { profile } = UserSessionStore();
  const { notif } = useNotification();

  const handleSendAccess = useCallback(async () => {
    if (resellerInfos?.user_email && profile?.uid) {
      setIsSendingAccess(true);
      try {
        const response = await UserApi.resendAccess({
          email: resellerInfos.user_email,
          lang: resellerInfos.lang,
          user_uid: profile.uid,
        });
        if (response?.data === "success") {
          notif({
            message: t("FORMS.SEND_SUCCESS"),
            type: "SUCCESS",
          });
        } else {
          throw new Error("NOT_SENT");
        }
      } catch (error) {
        notif({
          message: t("FORMS.SEND_FAILED"),
          type: "ERROR",
        });
      }
      setIsSendingAccess(false);
    }
  }, [t, preferredLanguage, resellerInfos?.user_email, profile?.uid]);

  useEffect(() => {
    updateState({ title: resellerInfos?.name ?? "" });
  }, [resellerInfos]);

  return (
    <>
      <TitleWrapper
        title={resellerInfos?.name ?? ""}
        handlePreviousButton={() => navigate("/resellers")}
      />
      <Paper sx={{ flexDirection: "row", justifyContent: "end", paddingY: 1 }}>
        <Button
          variant={"contained"}
          onClick={() => handleSendAccess()}
          disabled={!resellerInfos?.user_email || isSendingAccess}
        >
          {isSendingAccess && (
            <CircularProgress size={16} sx={{ marginRight: "8px" }} />
          )}
          {!isSendingAccess && (
            <Icon name={"send"} sx={{ marginRight: "8px" }} />
          )}
          {t("FORMS.SEND_ACCESS")}
        </Button>
      </Paper>
      <FormReseller />
    </>
  );
};

export default ResellersEdit;
