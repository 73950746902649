import BaseSelect from "@/components/base/select";
import useProfileInfo from "@/hooks/useProfileInfo";
import CompanyStore from "@/store/company";
import { useTranslation } from "react-i18next";

import PaperBlock from "@/components/widget/forms/base/paper-block";
import useLicences from "./useLicence";

const CompanyLicence = ({ control, formState }) => {
  const { t } = useTranslation();
  const { licences } = useLicences();
  const { disabledFields, isLoading } = CompanyStore();
  const { isRoleSuperAdmin, isSwitchAccount } = useProfileInfo();

  return (
    <PaperBlock
      title={t("COMPANIES.CREATE.LICENCE")}
      paperElevation={0}
      paperFullHeight
    >
      <BaseSelect
        label={t("FORMS.LICENCE")}
        required
        control={control}
        options={licences || []}
        formStateError={!!formState?.errors["price_uid"]}
        controlName="price_uid"
        disabled={
          isRoleSuperAdmin ? false : !!disabledFields?.price_uid || isLoading
        }
      />
    </PaperBlock>
  );
};
export default CompanyLicence;
