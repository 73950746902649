import ReactVirtualizedTable from "@/components/widget/table/partials/virtuoso-table";
import useTableComptes from "./useTableComptes";

const TableComptes = () => {
  const {
    columns,
    page,
    totalCount,
    rowsPerPage,
    rows,
    activeSort,
    handleChangePage,
    handleSort,
  } = useTableComptes();

  return (
    <ReactVirtualizedTable
      columns={columns}
      rows={rows}
      pagination={{
        page,
        rowsPerPage,
        totalCount,
      }}
      onPageChange={handleChangePage}
      onSort={handleSort}
      activeSort={activeSort}
    />
  );
};

export default TableComptes;
