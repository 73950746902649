import logoDilypse from "@/assets/images/logo-dilypse.png";
import Icon from "@/components/base/Icon";
import BaseInput from "@/components/base/input";
import useStyles from "@/pages/login/styles";
import { UiService, UserSessionService } from "@/services";
import ResellerStore from "@/store/reseller";
import UserSessionStore from "@/store/user-session";
import { decode64AndParse } from "@/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";

interface ReserPasswordForm {
  email: string;
  uid: string;
  password: string;
  confirm_password: string;
}

const ResetPasswordPage = () => {
  const { classes } = useStyles();

  const [apiError, setApiError] = useState("");
  const [showConfirmPasswordAdornment, setShowConfirmPasswordAdornment] =
    useState(false);
  const [showPasswordAdornment, setShowPasswordAdornment] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const { isConnected } = UserSessionStore();
  const { resellerLogo, resellerName } = ResellerStore();
  const defaultValues = useMemo((): ReserPasswordForm => {
    return {
      email: "",
      uid: "",
      password: "",
      confirm_password: "",
    };
  }, []);
  const schema = useMemo(() => {
    return z
      .object({
        email: z.string().email(),
        uid: z.string(),
        password: z
          .string()
          .trim()
          .min(8, t("PASSWORD.RESET.NOT_STRONG_ENOUGH_PASSWORD")),
        confirm_password: z
          .string()
          .trim()
          .min(1, t("LABEL.CONFIRM_PASSWORD_STRONG")),
      })
      .superRefine(({ password, confirm_password }, refinementContext) => {
        if (password.length >= 8 && confirm_password.length > 0) {
          if (password !== confirm_password) {
            setError("confirm_password", {
              type: "required",
              message: t("LABEL.CONFIRM_PASSWORD_STRONG"),
            });
            return refinementContext.addIssue({
              code: z.ZodIssueCode.custom,
              message: t("LABEL.CONFIRM_PASSWORD_STRONG"),
              path: ["confirm_password"],
            });
          } else {
            clearErrors();
          }
        }
      });
  }, [t]);

  const {
    formState,
    control,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    trigger,
  } = useForm<ReserPasswordForm>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: "all",
  });
  const formValue = useWatch({ control });
  const navigate = useNavigate();
  const onSubmit = useCallback(async () => {
    setLoading(true);
    setApiError("");

    if (!isConnected) {
      setApiError(t("SYSTEM_ERROR.INVALID_REQUEST"));
    } else {
      const initRequest = await UserSessionService.initPassword({
        email: formValue?.email,
        uid: formValue?.uid,
        password: formValue?.password,
        confirm_password: formValue?.confirm_password,
      });
      if (initRequest?.error) {
        setApiError(t("SYSTEM_ERROR.INVALID_REQUEST"));
      } else {
        window.location.href = "/login";
      }
    }

    setLoading(false);
  }, [formValue, isConnected]);

  useEffect(() => {
    const details = searchParams.get("details");
    if (details) {
      const detailsObject = decode64AndParse(details);
      if (detailsObject) {
        searchParams.delete("details");
        setValue("email", detailsObject?.email);
        setValue("uid", detailsObject?.uid);
      }
    } else {
      window.location.href = "/login";
    }
  }, [searchParams]);

  useEffect(() => {
    UiService.setState({ title: "PASSWORD.RESET.TITLE" });
    // UserSessionService.clearSession();
  }, []);

  return (
    <Box className={classes.loginWrapper}>
      {resellerLogo && (
        <img
          data-testid="logo"
          src={resellerLogo || logoDilypse}
          alt={resellerName || "Dilypse"}
        />
      )}

      <Typography variant="h1" className="title-header">
        {t("PASSWORD.RESET.TITLE_HEADER")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit, (errors) => console.log(errors))}>
        <BaseInput
          label={t("LABEL.EMAIL")}
          type="email"
          data-testid="email"
          value={formValue?.email}
          disabled
          startAdornment={<Icon name="envelope" />}
        />
        <BaseInput
          label={t("LABEL.NEW_PASSWORD")}
          type="password"
          data-testid="password"
          autoComplete="off"
          value={formValue?.password}
          required
          error={!!formState.errors["password"]}
          id="password"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue("password", e.target.value.trimStart());
            trigger(["password"]);
          }}
          onFocus={() => setShowPasswordAdornment(true)}
          onBlur={() => setShowPasswordAdornment(false)}
          helperText={
            !!formState.errors["password"] &&
            formState.errors["password"]?.message
          }
          startAdornment={
            (showPasswordAdornment || formValue?.password) && (
              <Icon name="lock" />
            )
          }
        />
        <BaseInput
          label={t("LABEL.CONFIRM_PASSWORD")}
          type="password"
          data-testid="confirm-password"
          autoComplete="off"
          required
          value={formValue?.confirm_password}
          error={!!formState.errors["confirm_password"]}
          id="confirmPassword"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue("confirm_password", e.target.value.trimStart());
            trigger(["confirm_password"]);
          }}
          onFocus={() => setShowConfirmPasswordAdornment(true)}
          onBlur={() => setShowConfirmPasswordAdornment(false)}
          helperText={
            !!formState.errors["confirm_password"] &&
            formState.errors["confirm_password"]?.message
          }
          startAdornment={
            (showConfirmPasswordAdornment || formValue?.confirm_password) && (
              <Icon name="lock" />
            )
          }
        />
        {apiError && (
          <Typography component={"span"} className="error">
            {apiError}
          </Typography>
        )}

        <Button
          variant="contained"
          type="submit"
          data-testid="resetButton"
          disabled={!formState?.isValid || loading}
        >
          {loading && <CircularProgress size={16} />}
          {t("BUTTON.RESET_PASSWORD")}
        </Button>
      </form>
    </Box>
  );
};

export default ResetPasswordPage;
