import CustomTable from "@/components/widget/table/partials/custom-table";
import useTableResellers from "./useTableResellers";

const TableResellers = () => {
  const { columns, page, totalCount, rowsPerPage, rows, handleChangePage } =
    useTableResellers();
  return (
    <CustomTable
      columns={columns}
      rows={rows}
      pagination={{
        page,
        rowsPerPage,
        totalCount,
      }}
      onPageChange={handleChangePage}
    />
  );
};

export default TableResellers;
