import BaseInputPhone from "@/components/base/input-phone";
import FormController from "@/components/widget/forms/base/form-controller";
import PaperBlock from "@/components/widget/forms/base/paper-block";
import { PHONE_COUNTRIES } from "@/data/phoneCountries";
import UiStore from "@/store/ui";
import { Stack } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const ResellerMainContact = ({
  control,
  formState,
  formValue,
  handleCompanyPhone,
}) => {
  const { t } = useTranslation();
  const { preferredLanguage } = UiStore();

  const langOfInputPhone = useMemo(() => {
    return preferredLanguage?.toLowerCase() || "fr-ca";
  }, [preferredLanguage]);

  return (
    <PaperBlock title={t("RESELLERS.MAIN_CONTACT")} titleVariant="h3">
      <Stack flexDirection={"column"} gap={1}>
        <Stack flexDirection={"row"} gap={1} className="fullWidthContent">
          <FormController
            fieldLabel={t("FORMS.FIRSTNAME")}
            fieldName="user_firstname"
            control={control}
          />
          <FormController
            fieldLabel={t("FORMS.LASTNAME")}
            fieldName="user_lastname"
            control={control}
          />
        </Stack>
        <BaseInputPhone
          label={t("FORMS.COMPANY_PHONE")}
          onlyCountries={PHONE_COUNTRIES}
          error={!!formState?.errors["business_phone"]}
          lang={langOfInputPhone}
          defaultCountry="CA"
          errorLabel={formState?.errors["business_phone"]?.message ?? ""}
          value={formValue?.business_phone}
          name="business_phone"
          onChange={handleCompanyPhone}
        />
        <FormController
          fieldLabel={t("FORMS.EMAIL")}
          fieldName="user_email"
          control={control}
        />
      </Stack>
    </PaperBlock>
  );
};

export default ResellerMainContact;
