import { CompanyApi } from "@/api";
import FormAction from "@/components/widget/forms/form-action";
import { socket } from "@/config/socket";
import useNotification from "@/hooks/useNotification";
import useAccountsStore from "@/store/accounts";
import UserSessionStore from "@/store/user-session";
import { CircularProgress, Paper, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as uuid from "uuid";
import Icon from "../Icon";
interface RandomKeyValuePairs extends Array<{ [key: string]: string }> {}
interface SocketData {
  companyNameList: string[];
  currentCount: number;
  failCount: number;
  successCount: number;
  totalCount: number;
  uid: string;
}
const SendingContacts = () => {
  const { t } = useTranslation();
  const { profile } = UserSessionStore();
  const uid = useMemo(() => uuid.v4(), []);
  const { notif } = useNotification();
  const {
    sendContacts,
    newContacts,
    update: updateAccount,
  } = useAccountsStore();
  const [socketResponse, setSocketResponse] = useState<SocketData>(null);
  const isUploadDone = useMemo(() => {
    return (
      socketResponse &&
      socketResponse?.currentCount === socketResponse?.totalCount
    );
  }, [socketResponse]);

  const clearContacts = useCallback(() => {
    socket.removeAllListeners(uid);
    updateAccount({
      sendContacts: false,
      newContacts: [],
    });
    if (socketResponse?.successCount !== 0) {
      notif({
        message: t("FORMS.ADD_SUCCESS"),
        type: "SUCCESS",
      });
    }
  }, [uid, socketResponse]);

  useEffect(() => {
    if (sendContacts) {
      socket.emit("connectCompany", {
        userId: profile?.uid,
        uid: uid,
      });

      socket.on("companyState", (res) => {
        setSocketResponse(res);
      });
      const upload = async () => {
        const finalObjects = [...newContacts].map((item) => {
          delete item?.id;
          delete item?.isValid;
          return item;
        });

        await CompanyApi.uploadCompanies({
          companies: finalObjects,
          user_uid: profile?.uid,
          request_id: uid,
        });
      };
      upload();
    }
  }, [sendContacts]);

  return (
    <>
      <Paper
        sx={{
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Stack
          flexDirection={"column"}
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={3}
        >
          {isUploadDone && (
            <Stack
              flexDirection={"column"}
              alignContent={"center"}
              alignItems={"center"}
              gap={3}
            >
              {socketResponse?.successCount > 0 && (
                <Typography variant="h3">
                  {socketResponse?.successCount}{" "}
                  {socketResponse?.successCount > 1
                    ? t("COMPANIES.ADDED_SUCCESS_N")
                    : t("COMPANIES.ADDED_SUCCESS")}
                </Typography>
              )}

              {socketResponse?.failCount !== 0 && (
                <Stack
                  flexDirection={"row"}
                  gap={"9.29px"}
                  sx={{
                    backgroundColor: "#FFF4E5",
                    borderRadius: "8px",
                    padding: "14px 16px",

                    width: "fit-content",
                  }}
                >
                  <Icon sx={{ marginRight: "18px" }} name="warning-yellow" />
                  <Stack>
                    <Typography
                      sx={{
                        color: "#663C00",
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 500,
                      }}
                    >
                      {socketResponse?.failCount}{" "}
                      {socketResponse?.failCount > 1
                        ? t("COMPANIES.CREATED_N")
                        : t("COMPANIES.CREATED")}
                    </Typography>
                    {socketResponse?.companyNameList?.map((item) => (
                      <Typography
                        key={item}
                        sx={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          color: "#663C00",
                          display: "block",
                        }}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
              )}
            </Stack>
          )}
          {!socketResponse && (
            <>
              <Typography
                component={"h2"}
                sx={{
                  fontWeight: 500,
                  fontSize: 24,
                  lineHeight: "32px",
                  color: "secondary.main",
                }}
                textAlign={"center"}
              >
                {t("COMPANIES.ADDING_COMPANY")}
              </Typography>
              <Typography
                component={"h3"}
                sx={{
                  fontWeight: 500,
                  fontSize: 20,
                  lineHeight: "32px",
                  color: "action.active",
                }}
                textAlign={"center"}
              >
                {t("COMPANIES.WAITING")}
              </Typography>
              <Stack justifyContent={"center"} flexDirection={"row"}>
                <CircularProgress
                  size={100}
                  sx={{
                    textAlign: "center",
                  }}
                />
              </Stack>
            </>
          )}
        </Stack>
      </Paper>
      <FormAction
        disableCancel={true}
        handleCancel={() => {}}
        isDisabled={!isUploadDone}
        isLoading={false}
        isUpdating
        enableIcon={false}
        handleSubmit={clearContacts}
        buttonText={t("MODAL.CONTINUE")}
      />
    </>
  );
};

export default SendingContacts;
