import { UserApi } from "@/api";
import BaseSelect from "@/components/base/select";
import FormController from "@/components/widget/forms/base/form-controller";
import PaperBlock from "@/components/widget/forms/base/paper-block";
import useNotification from "@/hooks/useNotification";
import CompanyStore from "@/store/company";
import UserSessionStore from "@/store/user-session";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CompanyAdmin = ({
  adminId,
  formValue,
  control,
  handleInvitationChange,
}) => {
  const { t } = useTranslation();
  const { disabledFields, isLoading, companyData } = CompanyStore();
  const { profile } = UserSessionStore();
  const [sendingAccess, setSendingAccess] = useState(false);
  const { notif } = useNotification();
  const [loginSent, setLoginSent] = useState(formValue?.login_send);

  const handleSendAccess = useCallback(async () => {
    if (companyData?.user_email && profile?.uid) {
      setSendingAccess(true);
      try {
        const response = await UserApi.resendAccess({
          email: companyData.user_email,
          lang: companyData.lang,
          user_uid: profile.uid,
        });
        if (response?.data === "success") {
          setLoginSent(true);
          notif({
            message: t("FORMS.SEND_SUCCESS"),
            type: "SUCCESS",
          });
        } else {
          throw new Error("NOT_SENT");
        }
      } catch (error) {
        notif({
          message: t("FORMS.SEND_FAILED"),
          type: "ERROR",
        });
      }
      setSendingAccess(false);
    }
  }, [t, companyData?.lang, companyData?.user_email, profile?.uid]);

  useEffect(() => {
    setLoginSent(formValue?.login_send);
  }, [formValue?.login_send]);

  return (
    <PaperBlock
      title={t("COMPANIES.CREATE.ADMIN")}
      paperElevation={1}
      buttonText={
        adminId &&
        (loginSent ? t("FORMS.RESEND_ACCESS") : t("FORMS.SEND_ACCESS"))
      }
      buttonOnClick={() => adminId && handleSendAccess()}
      buttonLoading={sendingAccess}
    >
      <Stack gap={1} flexDirection={"column"}>
        <Stack
          flexDirection="row"
          gap={1}
          className={"inputs fullWidthContent"}
        >
          <FormController
            control={control}
            fieldLabel={t("FORMS.FIRSTNAME")}
            fieldName="user_firstname"
            disabled={!!disabledFields?.user_firstname || isLoading}
          />
          <FormController
            control={control}
            fieldLabel={t("FORMS.LASTNAME")}
            fieldName="user_lastname"
            disabled={!!disabledFields?.user_lastname || isLoading}
          />
        </Stack>
        <Stack
          flexDirection="row"
          gap={1}
          className={"inputs fullWidthContent"}
        >
          <FormController
            control={control}
            fieldLabel={t("FORMS.EMAIL")}
            fieldName="user_email"
            disabled={!!disabledFields?.user_email || isLoading}
          />

          <BaseSelect
            label={t("FORMS.ROLE")}
            required
            disabled
            options={[
              {
                label: t("FORMS.NETWORK_ROLE"),
                value: "1",
              },
            ]}
            defaultValue={"1"}
            controlName="role"
          />
        </Stack>
      </Stack>

      {!adminId && (
        <FormControlLabel
          label={t("FORMS.SEND_INVITATION")}
          checked={formValue?.login_send}
          control={<Checkbox onChange={handleInvitationChange} />}
        ></FormControlLabel>
      )}
    </PaperBlock>
  );
};

export default CompanyAdmin;
