import TitleWrapper from "@/components/base/page-container/title-wrapper";
import FormCompany from "@/components/features/company/forms";
import UiStore from "@/store/ui";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CompaniesCreate = () => {
  const { updateState } = UiStore();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    updateState({ title: t("COMPANIES.NEW_COMPANY") });
  }, []);

  return (
    <>
      <TitleWrapper
        title={t("COMPANIES.NEW_COMPANY")}
        handlePreviousButton={() => navigate("/accounts")}
      />
      <FormCompany />
    </>
  );
};

export default CompaniesCreate;
