import FormLogo from "@/components/widget/forms/base/logo";
import PaperBlock from "@/components/widget/forms/base/paper-block";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const ResellerLogos = ({ formValue, setValue, formState }) => {
  const { t } = useTranslation();
  return (
    <PaperBlock title={t("RESELLERS.LOGO")} titleVariant="h3">
      <Stack flexDirection={"row"} gap={3}>
        <FormLogo
          logo={formValue?.logo}
          openUpload={() => setValue("showUploadLogoWhite", true)}
          logoTooltip={t("FORMS.LOGO_WHITE")}
          addTooltip={t("FORMS.LOGO_WHITE_ADD")}
          error={!!formState?.errors["logo"]}
        />
        <FormLogo
          logo={formValue?.logo_dark}
          openUpload={() => setValue("showUploadLogoDark", true)}
          logoTooltip={t("FORMS.LOGO_BLAKC")}
          addTooltip={t("FORMS.LOGO_DARK_ADD")}
          error={!!formState?.errors["logo_dark"]}
          darkBackground={true}
        />
        <FormLogo
          logo={formValue?.favicon}
          openUpload={() => setValue("showUploadFavicon", true)}
          logoTooltip={t("FORMS.FAVICON")}
          addTooltip={t("FORMS.FAVICON_ADD")}
          error={!!formState?.errors["favicon"]}
        />
      </Stack>
    </PaperBlock>
  );
};

export default ResellerLogos;
