import Notification from "@/components/widget/notification";
import useScrollTop from "@/hooks/useScrollTo";
import { CookiesService, ResellerService } from "@/services";
import LangService from "@/services/lang";
import ResellerStore from "@/store/reseller";
import UiStore from "@/store/ui";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
const UNPROTECTED_ROUTE = ["/login"];

const GlobalOutlet = () => {
  const { resellerName, resellerFetched } = ResellerStore();
  const { title, favicon, preferredLanguage, updateState } = UiStore();
  const { i18n, t, ready } = useTranslation();

  const { pathname } = useLocation();
  useScrollTop(".children-wrapper");

  useEffect(() => {
    const reseller = resellerName ? resellerName + " - " : "";
    document.title = title ? `${reseller} ${t(title)}`.trim() : resellerName;
  }, [resellerName, t, title]);

  useEffect(() => {
    if (favicon) {
      const link = document.querySelector("link#favicon");
      if (link) link.setAttribute("href", favicon + "?v=2");
    }
  }, [favicon]);

  useEffect(() => {
    function handleLanguageChange() {
      const language = LangService.preloadLanguage(navigator.languages);
      updateState({ preferredLanguage: language });
    }
    window.addEventListener("languagechange", handleLanguageChange);
    return () => {
      window.removeEventListener("languagechange", handleLanguageChange);
    };
  }, []);

  useEffect(() => {
    const language = LangService.preloadLanguage(navigator.languages);
    document.documentElement.lang = language;
    CookiesService.set({ lang: language });
    updateState({ preferredLanguage: language });
  }, []);

  useEffect(() => {
    const unprotectedRoute = UNPROTECTED_ROUTE.includes(
      window.location.pathname
    );
    if (!resellerFetched || (unprotectedRoute && !resellerFetched)) {
      (async () => {
        await ResellerService.getResellerInfos();
      })();
    }
  }, [pathname, resellerFetched]);

  useEffect(() => {
    i18n.changeLanguage(preferredLanguage);
  }, [preferredLanguage, i18n]);

  return (
    <React.Fragment>
      {ready && (
        <>
          <Notification />
          <Outlet />
        </>
      )}
    </React.Fragment>
  );
};

export default GlobalOutlet;
