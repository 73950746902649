import HeaderTitle from "@/components/base/header-title";

import TableResellers from "@/components/features/reseller/table/table-resellers";
import TopbarResellers from "@/components/features/reseller/topbar/topbar-resellers";
import UiStore from "@/store/ui";
import { Paper } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const ResellersPage = () => {
  const { t } = useTranslation();
  const { updateState } = UiStore();

  useEffect(() => {
    updateState({ title: t("MENU.RESELLERS") });
  }, []);
  return (
    <>
      <HeaderTitle icon="building-7" title={t("MENU.RESELLERS")} />
      <TopbarResellers />
      <Paper data-testid="table-resellers">
        <TableResellers />
      </Paper>
    </>
  );
};

export default ResellersPage;
