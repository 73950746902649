import Icon from "@/components/base/Icon";
import CustomTooltip from "@/components/base/Tooltip";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import StyledInput from "../styled-input";
import useSearchInputFilter from "./useSearchInputFilter";

type SearchPropsType = {
  inputLabel: string;
  popoverTitle: string;
  checkboxList: {
    label: string;
    name: string;
    checked: boolean;
    count: number;
  }[];
  filterText: string;
  textSearch?: string;
};

type FilterProps = {
  setWithSearch: Dispatch<SetStateAction<SearchPropsType>>;
  withSearch: SearchPropsType;
};

const SearchInputFilter = ({ setWithSearch, withSearch }: FilterProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [inputWidth, setInputWidth] = useState(0);
  const [checkedLength, setCheckedLength] = useState(0);
  const {
    handleCheckbox,
    handleClick,
    handleClose,
    handleInputRef,
    handleClearFilter,
    handleClearTextSearch,
    handleInputText,
  } = useSearchInputFilter({
    setAnchorEl,
    setInputWidth,
    setCheckedLength,
    setWithSearch,
    withSearch,
  });
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <>
      <StyledInput
        ref={handleInputRef}
        value={withSearch.textSearch}
        onInput={handleInputText}
        sx={{
          width: "100%",
          paddingRight: checkedLength > 0 && !open ? "8px" : "4px",
          "@media (min-width: 1024px)": {
            width: "400px",
          },
        }}
        data-testid="search-input"
        placeholder={t(withSearch.inputLabel)}
        endAdornment={
          <Stack flexDirection="row" sx={{ height: "28px" }}>
            {withSearch?.textSearch && (
              <Fragment>
                <CustomTooltip title={t("COMMON.ERASE_TEXT")}>
                  <IconButton onClick={handleClearTextSearch}>
                    <Icon name="close" />
                  </IconButton>
                </CustomTooltip>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{
                    marginLeft: "4px",
                    marginRight: "10px",
                    height: "24px",
                    alignSelf: "center",
                  }}
                />
              </Fragment>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
                padding: "2px 8px",
                ...(!open &&
                  !!checkedLength && {
                    bgcolor: "primary.light",
                    color: "primary.main",
                    borderRadius: "14px",
                  }),
              }}
            >
              <CustomTooltip title={t(withSearch.filterText)}>
                <IconButton
                  onClick={handleClick}
                  data-e2e="open-filter"
                  sx={{
                    p: 0,
                    color: !!checkedLength && !open && "primary.main",
                  }}
                >
                  <Icon name="filter" />
                </IconButton>
              </CustomTooltip>
              {!open && !!checkedLength && (
                <Typography component={"span"} sx={{ color: "inherit" }}>
                  {checkedLength}
                </Typography>
              )}
              {checkedLength > 0 && (
                <CustomTooltip title={t("COMMON.REMOVE_FILTER")}>
                  <IconButton
                    onClick={handleClearFilter}
                    sx={{ p: 0, color: !open && "primary.main" }}
                  >
                    <Icon name="close" />
                  </IconButton>
                </CustomTooltip>
              )}
            </Box>
          </Stack>
        }
      />
      <Popover
        disableRestoreFocus
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={8}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          root: {
            BackdropProps: {
              style: { backgroundColor: "transparent" },
            },
          },
          paper: {
            sx: {
              width: inputWidth,
              boxSizing: "border-box",
              p: "8px",
              gap: 0,
            },
          },
        }}
      >
        <Typography sx={{ color: "action.active", p: "8px" }}>
          {t(withSearch.popoverTitle)}
        </Typography>
        <List sx={{ py: 0 }}>
          {withSearch.checkboxList.map((value, k) => (
            <ListItem
              sx={{ py: 0, px: 1, boxShadow: "none" }}
              key={k}
              data-e2e="filter-option"
            >
              <FormControlLabel
                onChange={handleCheckbox}
                sx={{ flexGrow: 1 }}
                control={<Checkbox data-e2e="filter-type" />}
                label={t(value.label)}
                name={value.name}
                checked={value.checked}
              />
              <Typography
                component={"span"}
                sx={{ color: "action.active" }}
                data-filter="filter-active"
              >
                {value.count || 0}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default SearchInputFilter;
