import env from "@/config/env";
import Cookies from "js-cookie";
import { create } from "zustand";

export interface IUiStore {
  title: string;
  favicon: string;
  preferredLanguage: string;
  showMenu: boolean;
  currentPage: string;
  previousPage: string;
  showNewVersion: boolean;
  appVersion: string;
}

export interface IUiSetter {
  updateState: (update: Partial<IUiStore>) => void;
  setShowMenu: (value: boolean) => void;
  setShowNewVersion: (value: boolean) => void;
  setAppVersion: (value: string) => void;
}

const { resellerFavicon } = JSON.parse(Cookies.get(env.COOKIE_NAME) ?? "{}");
const showNewVersion =
  localStorage.getItem(`${env.APP_NAME}-${env.ENV}-show-new-version`) ?? "";
const appVersion =
  localStorage.getItem(`${env.APP_NAME}-${env.ENV}-version`) ?? "";

const initalState = {
  title: "",
  favicon: resellerFavicon,
  preferredLanguage: "",
  showMenu: false,
  currentPage: "",
  previousPage: "",
  showNewVersion: showNewVersion === "true",
  appVersion,
};

const UiStore = create<IUiStore & IUiSetter>((set) => ({
  ...initalState,
  updateState: (update) => set(() => ({ ...update })),
  setShowMenu: (showMenu) => set(() => ({ showMenu })),
  setShowNewVersion: (showNewVersion) => set(() => ({ showNewVersion })),
  setAppVersion: (appVersion) => set(() => ({ appVersion })),
}));

export default UiStore;
