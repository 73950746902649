import { create } from "zustand";

export interface INotificationStore {
  show: boolean;
  message: string;
  type: "ERROR" | "SUCCESS";
}

export interface INotificationSetter {
  update?: (update: Partial<INotificationStore>) => void;
}

const initalState: INotificationStore = {
  show: false,
  message: "",
  type: "SUCCESS",
};

const NotificationStore = create<INotificationStore & INotificationSetter>(
  (set) => ({
    ...initalState,
    update: (update) => set(() => ({ ...update })),
  })
);

export default NotificationStore;
