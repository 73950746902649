import { create } from "zustand";

export interface AccountType {
  name: string;
  address: string;
  zip: string;
  city: string;
  province_code: string;
  country_code: string;
  business_phone: string;
  business_uid?: string;
  lang: string;
  user_firstname: string;
  user_lastname: string;
  user_email: string;
  price_uid?: string;
  isValid?: boolean;
  hasDuplicate?: boolean;
  id: number;
}

export interface AccountBooleanType {
  name: boolean;
  address: boolean;
  zip: boolean;
  city: boolean;
  province_code: boolean;
  country: boolean;
  phone: boolean;
  user_lang: boolean;
  user_firstname: boolean;
  user_lastname: boolean;
  user_email: boolean;
}

type IAccountsStore = {
  filter: {
    status: string;
    search: string;
  };
  currentPage: number;
  count_by_status: {
    [status: string]: {
      status: string;
      count: number;
    };
  };
  newContacts: AccountType[];
  newContactsValidationArray: AccountBooleanType[];
  sendContacts: boolean;
};

export type IAccountsSetter = {
  update: (update: Partial<IAccountsStore>) => void;
  flush: () => void;
  clearOnList: () => void;
};

const initalState: IAccountsStore = {
  filter: {
    status: "",
    search: "",
  },
  currentPage: 0,
  count_by_status: {},
  newContacts: [],
  newContactsValidationArray: [],
  sendContacts: false,
};

const useAccountsStore = create<IAccountsStore & IAccountsSetter>((set) => ({
  ...initalState,
  update: (update) => set(() => ({ ...update })),
  flush: () => set(initalState),
  clearOnList: () =>
    set({
      filter: {
        status: "",
        search: "",
      },
      count_by_status: {},
    }),
}));

export default useAccountsStore;
