import useProfileInfo from "@/hooks/useProfileInfo";
import UserSessionStore from "@/store/user-session";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useRedirect = () => {
  const { isLoggedIn } = UserSessionStore();
  const navigate = useNavigate();
  const { isSuperAdmin } = useProfileInfo();
  const { pathname } = useLocation();

  useEffect(() => {
    const isBasePath = window.location.pathname === "/";
    const isLoginPage = window.location.pathname === "/login";
    const semiPrivateRoutes = ["/forgot-password", "/reset-password"];
    const isSemiPrivateRoute = semiPrivateRoutes.some((route) =>
      window.location.pathname.includes(route)
    );

    if (isLoggedIn && (isLoginPage || isBasePath || isSemiPrivateRoute)) {
      window.location.href = "/accounts";
      return;
    }
    if (isBasePath) {
      window.location.href = "/login";
    }
  }, [isLoggedIn, pathname, isSuperAdmin, navigate]);
};

export default useRedirect;
