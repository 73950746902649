import BaseInput from "@/components/base/input";
import PaperBlock from "@/components/widget/forms/base/paper-block";
import { useTranslation } from "react-i18next";

const CompanyUniqueId = ({ formValue }) => {
  const { t } = useTranslation();
  return (
    <PaperBlock title={t("COMPANIES.EDIT.IDENTITY")} paperElevation={0}>
      <BaseInput
        value={formValue?.network_uid}
        label={t("FORMS.ID_NETWORK")}
        disabled
      />
      <BaseInput
        value={formValue?.uid}
        label={t("FORMS.ID_COMPANY")}
        disabled
      />
    </PaperBlock>
  );
};

export default CompanyUniqueId;
