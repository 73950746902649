import { CookiesService } from "@/services";
import { AccountType } from "@/store/accounts";
import { CountryCode } from "libphonenumber-js";
import { ApiService } from "..";
interface OptionalCompanyData {
  uid?: string;
  front_base_url?: string;
}

export interface CreateCompanyPayload extends Partial<OptionalCompanyData> {
  user_uid?: string;
  name: string;
  city: string;
  zip: string;
  address: string;
  province_code?: string;
  country_code: CountryCode;
  price_uid?: string;
  business_uid?: string;
  network_name?: string;
  network_uid?: string;
  mobile_phone: string;
  business_phone: string;
  user_firstname: string;
  user_lastname: string;
  user_email: string;
  lang: string;
  login_send: boolean;
}

type ResponseApiList<T> = {
  metadata: Metadata;
  data: T[];
};

type LinksType = {
  type: string;
  feedback: boolean;
  value: string;
};

export type Companies = {
  company_uid: string;
  company_name: string;
  plan: string;
  location_status: string;
  vendor: string;
  location_name?: string;
  created?: string;
  links?: LinksType[];
};

export type BusinessStatusResponse = {
  status: string;
  count: number;
};

export type BusinessStatusParams = {
  user_uid: string;
};

type Metadata = {
  total_count: number;
  page_size: number;
  page_number: number;
  count_by_status: { status: string; count: number }[];
};

type CompanyListParams = {
  user_uid: string;
  page_number: number;
  page_size: number;
  sort_by?: string;
  sort_order?: "asc" | "desc";
  search?: string;
  status?: string;
};

export interface LicencesType {
  uid: string;
  name: string;
  credit: {
    type: string;
    quantity: number;
  }[];
}

export interface BusinessType {
  uid: string;
  name: string;
}

export interface NetworkType {
  network_name?: string;
  is_login_sent?: boolean;
  name?: string;
  city?: string;
  zip?: string;
  address?: string;
  province_code?: string;
  country_code?: string;
  price_uid?: string;
  business_uid?: string;
  mobile_phone?: string;
  business_phone?: string;
  user_firstname?: string;
  user_lastname?: string;
  user_email?: string;
  lang?: string;
  login_send?: boolean;
}

export interface UploadCompaniesProps {
  request_id: string;
  user_uid: string;
  companies: AccountType[];
}

class CompanyApi {
  createCompany = async (body: CreateCompanyPayload) => {
    return await ApiService.post(`/company`, {
      ...body,
      user_uid: CookiesService.get("user_uid"),
    });
  };

  updateCompany = async (body: CreateCompanyPayload) => {
    const uid = body?.uid;
    delete body?.uid;
    return await ApiService.put(`/company/${uid}`, {
      ...body,
      user_uid: CookiesService.get("user_uid"),
    });
  };

  list = async ({
    user_uid,
    page_number,
    page_size,
    sort_by,
    sort_order,
    search,
    status,
  }: CompanyListParams) => {
    return (await ApiService.get(`/accounts`, {
      user_uid,
      reseller_id: CookiesService.get("resellerUid"),
      _page: page_number === 0 ? 1 : page_number,
      _limit: page_size,
      _order: sort_order?.toLowerCase(),
      _sort: sort_by?.toLowerCase(),
      q: search,
      status: status?.toUpperCase(),
    })) as ResponseApiList<Companies>;
  };

  getBusinessesStatus = async ({ user_uid }: BusinessStatusParams) => {
    return (await ApiService.get(`/accounts/status`, {
      user_uid,
    })) as ResponseApiList<BusinessStatusResponse>;
  };

  getCompany = async ({ company_uid }) => {
    return await ApiService.get(`/company/${company_uid}`, {
      user_uid: CookiesService.get("user_uid"),
    });
  };

  getLicences = async (
    user_uid: string,
    company_uid: string
  ): Promise<LicencesType[]> => {
    return await ApiService.get("/prices", {
      user_uid,
      company_uid,
    });
  };

  getNetwork = async ({
    user_uid,
    keyword,
  }: {
    user_uid: string;
    keyword: string;
  }) => {
    return await ApiService.get("/network/search", {
      user_uid,
      keyword,
    });
  };

  getBusiness = async (): Promise<BusinessType[]> => {
    return await ApiService.get("/businesses");
  };

  getCredit = async (company_uid: string) => {
    return await ApiService.get(`/credit/${company_uid}`);
  };

  rechargeCredit = async ({ user_uid, company_uid }) => {
    return await ApiService.post(`/credit-recharge`, {
      company_uid,
      user_uid,
    });
  };

  deleteCompany = async ({ company_uid }) => {
    return await ApiService.delete(
      `/company/${company_uid}?user_uid=${CookiesService.get("user_uid")}`
    );
  };

  uploadCompanies = async ({
    request_id,
    user_uid,
    companies,
  }: UploadCompaniesProps) => {
    return await ApiService.post(`/companies`, {
      request_id,
      user_uid,
      companies,
    });
  };
}

export default new CompanyApi();
